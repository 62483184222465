<template>
  <div>
    <div class="hotline-wrapper">
      <div class="hotline-field">
        <a :href="hotlineLink">
          <Icon
            name="phone"
            alt-text="Telefonnummer"
            up="4"
            height="24"
            class="hotline-icon"
          />
          <span class="hotline-number" v-html="hotlineNumber" />
        </a>
      </div>
    </div>
    <p v-if="!hideCosts" class="hotline-costs slim">
      Kosten gemäß Ihrem {{ $brand.name }} Tarif (max. 9 Ct/Min Inland) von
      Ihrem {{ $brand.name }}
      Mobilfunkanschluss. Kosten für Anrufe von anderen Anschlüssen gemäß
      Preisliste des jeweiligen Anbieters.
    </p>
  </div>
</template>

<script>
import Icon from '../icon/icon.vue'

export default {
  components: { Icon },
  props: {
    hideCosts: {
      type: Boolean,
      default: false
    },
    hotlineLink: {
      type: String,
      default: 'tel:+4917688880000'
    },
    hotlineNumber: {
      type: String,
      default: '0176&nbsp;8888&nbsp;0000'
    }
  }
}
</script>

<style lang="scss" scoped>
.hotline-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  margin-top: 5px;
  margin-bottom: 10px;
}

.hotline-field {
  display: flex;
  align-items: center;
  background-color: #f3f6f8;
  padding: 10px 18px;
  border-radius: 30px;

  a {
    text-decoration: none;
  }
}

.hotline-icon {
  margin-right: 5px;

  :deep(svg) {
    fill: $root-color;
  }
}

.hotline-number {
  @include font-normal;

  color: $root-color;
  font-size: 1.4rem;
}

.hotline-costs {
  line-height: 1.2rem;
}
</style>
